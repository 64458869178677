
import { CommonCalendarEventRouterView, UserType } from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    CommonCalendarEventRouterView,
  },
  setup() {
    return {
      UserType,
    }
  },
})
